<template>
<div class="col-sm-12 result">
  <div class="col-sm-12 p-3 text-center">
    <a
      class="product-link"
      :href="productUrl"
      target="_blank"
    ><strong>{{ parsedProductName }}</strong></a>
  </div>
  <div class="row col">
    <div class="col-sm-4 col-md-2 text-center">
      <a class="product-link" :href="productUrl" target="_blank">
        <img
          :alt="parsedProductName"
          class="product-thumb"
          :src="productThumb"
          loading="lazy"
          >
      </a>
    </div>
    <div class="col-sm-8 col-md-7 m-auto">
      <div class="row">
      </div>
      <div class="row text-center">
        <template v-if="productAvailable">
          <span class="col"><strong>Price</strong>&nbsp;{{ formattedPrice }}</span>
        </template>
        <span class="col"><strong>{{ formattedAvailability }}</strong></span>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 text-center m-auto">
      <a
        class="product-link"
        :href="productUrl"
        target="_blank">
        <div class="shop-image" :class="shopKey"></div>
      </a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    productName: null,
    productUrl: null,
    productThumb: null,
    productAvailable: {
      type: Boolean,
      default: false
    },
    shopKey: null,
    formattedAvailability: null,
    formattedPrice: null
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    parsedProductName()
    {
      return unescape(decodeURI(this.productName));
    },
    hasPrice() {
      return this.formattedPrice != null && this.formattedPrice != '';
    }
  },
  methods: {
  }

}
</script>

